import { matchPath } from "react-router-dom-v5-compat";

import { linkToHelpSectionArticle } from "common/links";

import { ROUTES } from "routing/routes";

export function linkToTransferPremium(languageCode: string = "en") {
  return linkToHelpSectionArticle(languageCode, "2855334");
}

export function linkToChangeAccountType(languageCode: string = "en") {
  return linkToHelpSectionArticle(languageCode, "2864487");
}

export function isInParentSettings(pathname: string) {
  return matchPath(`${ROUTES.PARENT.SETTINGS.path}/*`, pathname);
}
