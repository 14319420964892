import { createSelector } from "reselect";

import { composeSelector } from "seneca-common/utils/selectors/compose-selectors";

import {
  SUBSCRIPTION_PRODUCT_PAYMENT_FREQUENCIES,
  SubscriptionProduct
} from "../../state";
import { PATH_TO_SUBSCRIPTION_PRODUCTS_STATE } from "../consts";
import SubscriptionProductsStateSlice from "../models/SubscriptionProductsStateSlice";
import { getSubscriptionProductsSlice } from "./state";
import * as subscriptionProductsSelectors from "./state/subscription-products";
import * as subscriptionProductSelectors from "./state/subscription-products/subscription-product";

type State = any;

export function getSubscriptionProductsStateSlice(
  state: State
): SubscriptionProductsStateSlice {
  return state.get(PATH_TO_SUBSCRIPTION_PRODUCTS_STATE);
}

export const getSubscriptionProducts = (state: State) =>
  getSubscriptionProductsSlice(getSubscriptionProductsStateSlice(state));

// subscription-products
export const getSubscriptionProduct = (
  state: State,
  subscriptionProductId: string
) =>
  subscriptionProductsSelectors.getSubscriptionProduct(
    getSubscriptionProducts(state),
    subscriptionProductId
  ) as SubscriptionProduct;

export const getSubscriptionProductIds = createSelector(
  getSubscriptionProducts,
  subscriptionProductsSelectors.getSubscriptionProductIds
);

export const getSortedAnnualSubscriptionProductIds = createSelector(
  getSubscriptionProducts,
  subscriptionProductsSelectors.getSortedAnnualSubscriptionProductIds
);

export const getSortedMonthlySubscriptionProductIds = createSelector(
  getSubscriptionProducts,
  subscriptionProductsSelectors.getSortedMonthlySubscriptionProductIds
);

export const makeGetSortedPromotedPeriodSubscriptionProductIds = (
  period: string
) =>
  createSelector(getSubscriptionProducts, subscriptionProducts => {
    return subscriptionProductsSelectors.getSortedPromotedPeriodSubscriptionProductIds(
      subscriptionProducts,
      period
    );
  });

export const getSubscriptionTierName = composeSelector(
  getSubscriptionProducts,
  subscriptionProductsSelectors.getSubscriptionTierName
);

export const subscriptionProductExists = (
  state: State,
  subscriptionProductId: string
): boolean => !!getSubscriptionProduct(state, subscriptionProductId);

// subscription-product
export const getSubscriptionProductDescription = (
  state: State,
  subscriptionProductId: string
) =>
  subscriptionProductSelectors.getDescription(
    getSubscriptionProduct(state, subscriptionProductId)
  );

export const getSubscriptionProductPrice = (
  state: State,
  subscriptionProductId: string
) =>
  subscriptionProductSelectors.getPrice(
    getSubscriptionProduct(state, subscriptionProductId)
  );

export const getSameTierProducts = (
  state: State,
  subscriptionProductId: string
) => {
  const products = getSubscriptionProducts(state);
  const tierName = getSubscriptionTierName(state, subscriptionProductId);

  return products.filter(
    product => subscriptionProductSelectors.getTierName(product) === tierName
  );
};

export const getSameTierProductSeasonalPrice = (
  state: State,
  subscriptionProductId: string
) => {
  const sameTierProducts = getSameTierProducts(state, subscriptionProductId);
  const seasonalProduct = sameTierProducts.find(
    product =>
      subscriptionProductSelectors.getPaymentFrequency(product) ===
      SUBSCRIPTION_PRODUCT_PAYMENT_FREQUENCIES.seasonal
  );
  return (
    seasonalProduct && subscriptionProductSelectors.getPrice(seasonalProduct)
  );
};

export const getSameTierProductAnnualPrice = (
  state: State,
  subscriptionProductId: string
) => {
  const sameTierProducts = getSameTierProducts(state, subscriptionProductId);
  const annualProduct = sameTierProducts.find(
    product =>
      subscriptionProductSelectors.getPaymentFrequency(product) ===
      SUBSCRIPTION_PRODUCT_PAYMENT_FREQUENCIES.annually
  );
  return annualProduct && subscriptionProductSelectors.getPrice(annualProduct);
};

export const subscriptionProductHasGuaranteedGrades = (
  state: State,
  subscriptionProductId: string
) =>
  subscriptionProductSelectors.hasGuaranteedGrades(
    getSubscriptionProduct(state, subscriptionProductId)
  );

export const getGuaranteeAddonFromSubscriptionProduct = (
  state: State,
  subscriptionProductId: string
) => {
  const addonProductIds = subscriptionProductSelectors.getAddonProductIds(
    getSubscriptionProduct(state, subscriptionProductId)
  );
  return addonProductIds.find(addonProductId =>
    subscriptionProductSelectors.hasGuaranteedGrades(
      getSubscriptionProduct(state, addonProductId)
    )
  );
};

export const getSubscriptionProductAddonProductIds = (
  state: State,
  subscriptionProductId: string
) =>
  subscriptionProductSelectors.getAddonProductIds(
    getSubscriptionProduct(state, subscriptionProductId)
  );

export const subscriptionProductHasSmartLearningMode = (
  state: State,
  subscriptionProductId: string
) =>
  subscriptionProductSelectors.hasSmartLearningMode(
    getSubscriptionProduct(state, subscriptionProductId)
  );

export const subscriptionProductHasWrongAnswersMode = (
  state: State,
  subscriptionProductId: string
) =>
  subscriptionProductSelectors.hasWrongAnswersMode(
    getSubscriptionProduct(state, subscriptionProductId)
  );

export const subscriptionProductHasQuizMode = (
  state: State,
  subscriptionProductId: string
) =>
  subscriptionProductSelectors.hasQuizMode(
    getSubscriptionProduct(state, subscriptionProductId)
  );

export const subscriptionProductHasAiAssistant = (
  state: State,
  subscriptionProductId: string
) =>
  subscriptionProductSelectors.hasAiAssistant(
    getSubscriptionProduct(state, subscriptionProductId)
  );

export const subscriptionProductHasAiQuizGeneration = (
  state: State,
  subscriptionProductId: string
) =>
  subscriptionProductSelectors.hasAiQuizGeneration(
    getSubscriptionProduct(state, subscriptionProductId)
  );

export const subscriptionProductHasTutoring = (
  state: State,
  subscriptionProductId: string
) =>
  subscriptionProductSelectors.hasTutoring(
    getSubscriptionProduct(state, subscriptionProductId)
  );

export const subscriptionProductHasExamQuestions = (
  state: State,
  subscriptionProductId: string
) =>
  subscriptionProductSelectors.hasExamQuestions(
    getSubscriptionProduct(state, subscriptionProductId)
  );

export const subscriptionProductHasParentPremiumFeatures = (
  state: State,
  subscriptionProductId: string
) =>
  subscriptionProductSelectors.hasParentPremiumFeatures(
    getSubscriptionProduct(state, subscriptionProductId)
  );

export const getSubscriptionProductPaymentFrequency = (
  state: State,
  subscriptionProductId: string
) =>
  subscriptionProductSelectors.getPaymentFrequency(
    getSubscriptionProduct(state, subscriptionProductId)
  );

export const subscriptionProductIsBilledAnually = (
  state: State,
  subscriptionProductId: string
) =>
  subscriptionProductSelectors.subscriptionIsBilledAnually(
    getSubscriptionProduct(state, subscriptionProductId)
  );

export const subscriptionProductIsBilledMonthly = (
  state: State,
  subscriptionProductId: string
) =>
  subscriptionProductSelectors.subscriptionIsBilledMonthly(
    getSubscriptionProduct(state, subscriptionProductId)
  );

export const subscriptionProductPaymentFrequency = (
  state: State,
  subscriptionProductId: string
) =>
  subscriptionProductSelectors.getPaymentFrequency(
    getSubscriptionProduct(state, subscriptionProductId)
  );

export const getSubscriptionProductIconUrl = (
  state: State,
  subscriptionProductId: string
) =>
  subscriptionProductSelectors.getIconUrl(
    getSubscriptionProduct(state, subscriptionProductId)
  );

export const getSubscriptionProductCanBeUpgraded = (
  state: State,
  subscriptionProductId: string
) =>
  subscriptionProductSelectors.getCanBeUpgraded(
    getSubscriptionProduct(state, subscriptionProductId)
  );

export const getSubscriptionProductIsAddOn = (
  state: State,
  subscriptionProductId: string
) =>
  subscriptionProductSelectors.isAddOn(
    getSubscriptionProduct(state, subscriptionProductId)
  );

export const getSubscriptionProductIsMisBooster = (
  state: State,
  subscriptionProductId: string
) =>
  subscriptionProductSelectors.isMisBooster(
    getSubscriptionProduct(state, subscriptionProductId)
  );

export const getTotalPriceByProductIds = (
  state: State,
  productIds: string[]
) => {
  return productIds.reduce((totalPrice, id) => {
    const price = getSubscriptionProductPrice(state, id);
    return totalPrice + (price || 0);
  }, 0);
};
