import { TUTORING_LEAD_SOURCES } from "@seneca/tutoring-service-consts";

import {
  boolean,
  number,
  route,
  string,
  type
} from "seneca-common/utils/routing/react-router-typesafe-routes/dom";

import { LinkedAccountsSource } from "features/settings/features/linked-accounts/analytics";
import { TeacherPlatformGatingEventRoutingType } from "features/teachers/features/gating/types";

import {
  AnyAnswerArrayType,
  AnyQuestionArrayType,
  anyValidator,
  assignmentTableModeType,
  courseAddedLocationType,
  courseSubscriptionTiersType,
  createAssignmentSourceType,
  iosSubscriptionFlowType,
  linkedAccountSourceType,
  linkPropsType,
  locationType,
  MiniMockSessionDataType,
  numberArrayType,
  oneToOneTutoringSourceType,
  optionalLocationType,
  parentAutoInvitesFlowType,
  parentPlatformPreviewSourceType,
  preselectClassesOptionType,
  schoolQuoteFormTypeType,
  schoolQuoteSourcesType,
  sectionContentTypeType,
  selectedCourseContentType,
  selectedQuizzesType,
  shareSourceType,
  speedyMarkingModeType,
  subscriptionJourneySourcesType,
  toValidator,
  tutoringFormModalStepType,
  userAccountTypeType
} from "./types";

export const ASSIGNMENT_LIST_ROUTES = {
  TODO: route("todo"),
  DONE: route("done"),
  MISSED: route("missed")
};

export const TEACHER_ASSIGNMENT_ROUTES = {
  CLASSES: route("classes"),
  STUDENTS: route("students"),
  EDIT: route("edit")
};

export const KNOWLEDGE_GAPS_ROUTE = route(
  "knowledge-gaps",
  {
    state: { formData: type(anyValidator) }
  },
  {
    SECTIONS: route("sections"),
    DETAILS: route("details")
  }
);

export const RESOURCE_DISCOVER_ROUTES = route(
  "resource-discover",
  {},
  {
    OVERVIEW: route("overview", {
      searchParams: {
        source: string().default("")
      }
    }),
    PREVIEW: route(
      "preview",
      {},
      {
        RESOURCE: route("resource/:resourceId", {
          params: { resourceId: string().default("") }
        })
      }
    ),
    ADD_RESOURCE: route("add-resource")
  }
);

export const ROUTES = route(
  "",
  { state: { isBackground: boolean() } },
  {
    PARENT: route(
      "parent",
      {},
      {
        CHILD: route(
          "child/:childId",
          { params: { childId: string().default("") } },
          {
            ASSIGNMENTS: route("assignments", {}, ASSIGNMENT_LIST_ROUTES),
            REPORTS: route(
              "report",
              {},
              {
                WEEKLY: route("weekly", {
                  searchParams: {
                    week: number(),
                    year: number()
                  }
                }),
                MONTHLY: route("monthly", {
                  searchParams: {
                    month: number(),
                    year: number()
                  }
                })
              }
            ),
            TEACHER_REPORT: route(
              "teacher-report-cards",
              {},
              {
                CARDS: route("overview", {
                  state: {
                    isInternal: boolean()
                  }
                }),
                CARD: route("card/:reportId/:createdAt/overview", {
                  params: {
                    reportId: string().default(""),
                    createdAt: string().default("")
                  },
                  state: {
                    isInternal: boolean(),
                    createdAt: string().default("")
                  }
                })
              }
            ),
            OVERVIEW: route(
              "overview",
              {},
              {
                FEED: route("feed"),
                CONNECT_TRANSFER: route("connect-transfer")
              }
            )
          }
        ),
        SETTINGS: route(
          "settings",
          {},
          {
            PERSONAL_INFORMATION: route("personal-information"),
            PASSWORD: route("password"),
            PREMIUM: route("premium"),
            NOTIFICATIONS: route("notifications")
          }
        )
      }
    ),
    DASHBOARD: route(
      "dashboard",
      {},
      {
        CLASSES: route("classes"),
        ASSIGNMENTS: route(
          "assignments",
          {},
          {
            ...ASSIGNMENT_LIST_ROUTES,
            ASSIGNMENT: route("assignment/:assignmentId", {
              params: { assignmentId: string().default("") }
            })
          }
        ),
        CLASS: route(
          "class/:classId",
          { params: { classId: string().default("") } },
          {
            ASSIGNMENTS: route("assignments", {}, ASSIGNMENT_LIST_ROUTES),
            LEADERBOARDS: route("leaderboards"),
            SETTINGS: route("settings")
          }
        ),
        SETTINGS: route(
          "settings",
          {},
          {
            PERSONAL_INFORMATION: route("personal-information"),
            LINKED_ACCOUNTS: route("linked-accounts", {
              state: {
                source: linkedAccountSourceType
              }
            }),
            PASSWORD: route("password"),
            PREMIUM: route("premium"),
            NOTIFICATIONS: route("notifications")
          }
        ),
        MORE: route("more")
      }
    ),
    TEACHER: route(
      "teacher",
      {},
      {
        HOME: route(
          "home",
          { state: { background: locationType } },
          {
            RESOURCE_DISCOVER: RESOURCE_DISCOVER_ROUTES
          }
        ),
        SETTINGS: route(
          "settings",
          {},
          {
            PERSONAL_INFORMATION: route("personal-information"),
            PASSWORD: route("password"),
            NOTIFICATIONS: route("notifications")
          }
        ),
        CLASS: route(
          "class/:classId",
          {
            params: { classId: string().default("") }
          },
          {
            ADD_COURSES: route("add-courses"),
            ASSIGNMENTS: route(
              "assignments",
              {},
              {
                OVERVIEW: route("overview"),
                ASSIGNMENT: route(
                  "assignment/:assignmentId",
                  {
                    params: { assignmentId: string().default("") }
                  },
                  {
                    REASSIGN: route(
                      "reassign",
                      {},
                      {
                        SECTION: route("section"),
                        DUE: route("due")
                      }
                    ),
                    SPEEDY_MARKING: route(
                      "speedy-marking",
                      {
                        searchParams: { mode: speedyMarkingModeType.defined() },
                        state: {
                          selectedQuestionIds: string().default("").array(),
                          numOfAnswersBeforeMarking: number().default(0)
                        }
                      },
                      {
                        FINISH: route("finish"),
                        QUIT: route("quit")
                      }
                    )
                  }
                )
              }
            ),
            COURSES: route("courses"),
            EDIT_ASSIGNMENT: route(
              "edit-assignment/:assignmentId",
              {
                state: {
                  fromPreviousLink: boolean(),
                  selectedClassIdsToInvite: string().defined().array(),
                  navigateProps: linkPropsType
                }
              },
              {
                TOPICS: route("topics", {
                  searchParams: {
                    searchTopic: string(),
                    contentTier: courseSubscriptionTiersType
                  }
                }),
                EXAM_QUESTIONS: route("exam-questions"),
                DETAILS: route("details"),
                INVITE_PARENTS: route("invite-parents"),
                VOCABULARY: route("vocabulary")
              }
            ),
            GRADES: route(
              "grades",
              {},
              {
                GRADEBOOK: route("gradebook"),
                INSIGHTS_TOPIC: route("topic"),
                INSIGHTS_COURSE: route("course")
              }
            ),
            INVITE_STUDENTS: route("invite-students"),
            OVERVIEW: route("overview"),
            SETTINGS: route("settings"),
            STUDENTS: route("students"),
            STUDENT: route(
              "student/:studentId",
              {
                params: {
                  studentId: string().default("")
                }
              },
              {
                REPORT_CARD: route(
                  "report-card",
                  {},
                  {
                    PREVIEW: route("preview", {
                      state: {
                        isInternal: boolean()
                      }
                    }),
                    SENDING: route("sending", {
                      state: {
                        isDirectlyAccessible: boolean()
                      }
                    }),
                    SUCCESS: route("success", {
                      state: {
                        isDirectlyAccessible: boolean()
                      }
                    }),
                    CONFIRM_BATCH_SEND: route("confirm-batch-send", {
                      state: {
                        isDirectlyAccessible: boolean(),
                        selectedStudentIds: string().defined().array()
                      }
                    }),
                    BATCH_SENDING: route("batch-sending", {
                      state: {
                        isDirectlyAccessible: boolean()
                      }
                    }),
                    BATCH_SUCCESS: route("batch-success", {
                      state: {
                        isDirectlyAccessible: boolean()
                      }
                    }),
                    SYNCED_SCHOOL_GATING: route("synced-school-gating", {
                      state: {
                        isDirectlyAccessible: boolean()
                      }
                    })
                  }
                )
              }
            ),
            KNOWLEDGE_GAPS: KNOWLEDGE_GAPS_ROUTE
          }
        ),
        CLASSES: route("classes", {
          searchParams: { preselectClasses: preselectClassesOptionType },
          state: { deselectClasses: boolean() }
        }),
        ASSIGNMENTS: route(
          "assignments",
          {},
          {
            CREATE: route(
              "create",
              {
                state: {
                  withClassSelection: boolean(),
                  source: createAssignmentSourceType,
                  classId: string().default(""),
                  selectedClassIdsToInvite: string().default("").array(),
                  fromPreviousLink: boolean(),
                  selectedQuizzes: selectedQuizzesType,
                  selectedCourseContent: selectedCourseContentType,
                  goBackLocation: locationType
                }
              },
              {
                CLASS: route("class", {
                  state: { clearSelectedClasses: boolean() }
                }),
                TYPE: route("type"),
                TOPICS: route("topics", {
                  searchParams: {
                    searchTopic: string(),
                    contentTier: courseSubscriptionTiersType
                  }
                }),
                QUIZZES: route("quizzes"),
                VOCABULARY: route("vocabulary"),
                EXAM_QUESTIONS: route("exam-questions"),
                DETAILS: route("details"),
                INVITE_PARENTS: route("invite-parents")
              }
            ),
            ASSIGNMENT: route(
              ":assignmentId",
              {
                params: { assignmentId: string().default("") },
                state: {
                  previousPathname: string().default("")
                }
              },
              TEACHER_ASSIGNMENT_ROUTES
            )
          }
        ),
        RESOURCE_HUB: route(
          "resource-hub",
          {
            searchParams: {
              term: string().default("")
            }
          },
          {
            ME: route("me"),
            SCHOOL: route("school"),
            PREVIEW: route(
              "preview",
              {
                state: {
                  previousLocation: locationType
                }
              },
              {
                QUIZ: route("quiz/:quizId", {
                  params: { quizId: string() }
                }),
                SECTION: route("section/:sectionId", {
                  params: { sectionId: string() }
                })
              }
            )
          }
        ),
        REPORTS: route(
          "reports",
          {
            searchParams: {
              requestId: string().default(""),
              initialStartDate: string(),
              initialEndDate: string()
            }
          },
          {
            STUDENTS: route("students"),
            TEACHERS: route("teachers"),
            PARENTS: route("parents")
          }
        ),
        SCHOOL: route(
          "school",
          {},
          {
            CLASSES: route("classes"),
            STUDENTS: route("students"),
            TEACHERS: route("teachers")
          }
        ),
        QUIZZES: route(
          "quizzes",
          {},
          {
            QUIZ: route(
              ":quizId",
              {
                params: { quizId: string().default("") },
                state: {
                  previousLocation: locationType
                }
              },
              {
                DELETE_MODAL: route("delete"),
                SAVE_MODAL: route("save", {
                  state: {
                    lastLocation: locationType,
                    quizId: string().default("")
                  }
                })
              }
            ),
            CREATE: route(
              "create",
              { state: { lastLocation: locationType } },
              {
                DETAILS: route("details"),
                INPUT: route(
                  "input",
                  {},
                  {
                    PASTE_TEXT: route("paste-text"),
                    YOUTUBE_LINK: route("youtube-link"),
                    UPLOAD_FILE: route("upload-file")
                  }
                ),
                GENERATING: route("generating")
              }
            ),
            SCHOOL: route("school")
          }
        ),
        STUDENT_INSIGHTS: route(
          "student-insights",
          {},
          {
            OVERVIEW: route("overview"),
            KNOWLEDGE_GAPS: KNOWLEDGE_GAPS_ROUTE,
            REPORT_CARD: route(
              "report-card",
              {},
              {
                SELECT: route("select"),
                PREVIEW: route("preview"),
                SENDING: route("sending", {
                  state: {
                    isDirectlyAccessible: boolean()
                  }
                }),
                SUCCESS: route("success", {
                  state: {
                    isDirectlyAccessible: boolean()
                  }
                }),
                CONFIRM_BATCH_SEND: route("confirm-batch-send", {
                  state: {
                    isDirectlyAccessible: boolean(),
                    reportsCount: number()
                  }
                }),
                BATCH_SENDING: route("batch-sending", {
                  state: {
                    isDirectlyAccessible: boolean()
                  }
                }),
                BATCH_SUCCESS: route("batch-success", {
                  state: {
                    isDirectlyAccessible: boolean()
                  }
                }),
                SYNCED_SCHOOL_GATING: route("synced-school-gating", {
                  state: {
                    isDirectlyAccessible: boolean()
                  }
                })
              }
            )
          }
        ),
        WHAT_IS_PREMIUM: route(
          "what-is-premium",
          {
            state: { source: subscriptionJourneySourcesType }
          },
          {
            GET_A_QUOTE: route(
              "get-a-quote",
              {
                searchParams: {
                  formType: schoolQuoteFormTypeType,
                  source: schoolQuoteSourcesType
                },
                state: {
                  isInternal: boolean()
                }
              },
              {
                SUCCESS: route("success")
              }
            )
          }
        ),
        RESOURCES: route("resources"),
        BECOME_A_TEACHER: route("become-a-teacher", {
          state: { referrer: locationType }
        })
      }
    ),
    CLASSROOM: route(
      "classroom",
      {},
      {
        COURSE: route(
          "course/:courseId",
          {
            params: { courseId: string().default("") }
          },
          {
            SECTION: route(
              "section/:sectionId",
              {
                params: { sectionId: string().default("") },
                searchParams: {
                  mode: string().default("")
                }
              },
              {
                SESSION: route(
                  "session",
                  {},
                  {
                    START: route("start")
                  }
                ),
                CONTENT: route(
                  "content/:contentId",
                  {
                    params: { contentId: string().default("") }
                  },
                  {
                    MODULE: route("module/:moduleId", {
                      params: {
                        moduleId: string().default("")
                      }
                    })
                  }
                ),
                HOMEPAGE: route("section-overview"),
                EXAM_QUESTIONS: route(
                  "exam-questions",
                  {},
                  {
                    SESSION: route("session")
                  }
                )
              }
            ),
            ASK_AMELIA: route(
              "ask-amelia",
              {},
              {
                CHAT: route(
                  "chat/:sessionId",
                  {
                    params: { sessionId: string().default("") },
                    state: {
                      isNewSession: boolean().default(false),
                      sectionId: string()
                    }
                  },
                  {
                    DELETE_MODAL: route("delete"),
                    RENAME_MODAL: route("rename")
                  }
                )
              }
            ),
            EXAM_QUESTIONS: route(
              "exam-questions",
              {},
              {
                MINI_MOCK: route(
                  "mini-mock",
                  {},
                  {
                    SESSION: route("session", {
                      state: {
                        miniMockSessionData: MiniMockSessionDataType,
                        selectedQuestions: AnyQuestionArrayType,
                        endTime: string().default(""),
                        sessionId: string()
                      }
                    }),
                    RESULTS: route("results", {
                      state: {
                        questions: AnyQuestionArrayType,
                        answers: AnyAnswerArrayType,
                        miniMockSessionData: MiniMockSessionDataType
                      }
                    })
                  }
                )
              }
            ),
            ASSIGNMENTS: route("assignments", {}, ASSIGNMENT_LIST_ROUTES),
            MULTI_SECTION: route(
              "multi-section",
              {},
              {
                TOPICS: route("topics", {
                  searchParams: {
                    searchTopic: string(),
                    contentTier: courseSubscriptionTiersType
                  }
                }),
                SESSION: route("session", {
                  state: {
                    selectedSectionIds: string().default("").array(),
                    moduleIdsWhitelist: string().default("").array()
                  }
                }),
                RESULTS: route("results", {
                  state: {
                    selectedSectionIds: string().default("").array(),
                    moduleIdsWhitelist: string().default("").array(),
                    sessionId: string().default("")
                  }
                })
              }
            ),
            RECOMMENDED_SESSION: route("new-session"),
            VOCABULARY: route(
              "vocabulary/:wordId",
              {
                params: { wordId: string().default("") },
                state: {
                  locationFrom: string().default(""),
                  assignmentId: string().default("")
                }
              },
              {
                SESSION: route(
                  "session",
                  {},
                  {
                    START: route("start")
                  }
                )
              }
            )
          }
        ),
        QUIZ_SESSION: route("quiz-session/:quizId", {
          params: { quizId: string().default("") },
          state: {
            locationFrom: string().default(""),
            assignmentId: string().default(""),
            completesAssignment: boolean().default(false),
            reviewingSession: boolean().default(false)
          }
        }),
        ENGLISH_DEMO: route(
          "english-demo",
          {},
          {
            CREATE: route(
              "create",
              {},
              { TOPIC: route("topic"), THEME: route("theme") }
            )
          }
        )
      }
    ),
    DIAGNOSIS: route("diagnosis", {
      state: {
        noUpdateRedirect: boolean()
      }
    }),
    UPDATE: route("update", {
      state: {
        redirectPostUpdate: type(toValidator),
        redirectState: type(anyValidator)
      }
    }),
    RESOURCE_DISCOVER: RESOURCE_DISCOVER_ROUTES,
    SIGN_UP_PARENT: route(
      "sign-up-parent",
      {
        searchParams: {
          showPasswordSignUp: boolean().default(false),
          connectionKey: string()
        },
        state: {
          email: string(),
          errorMessage: string()
        }
      },
      {
        USER_DETAILS: route("user-details"),
        CHECK_YOUR_EMAIL: route("check-your-email", {
          state: {
            email: string().default(""),
            givenName: string(),
            familyName: string()
          }
        })
      }
    ),
    LOGIN_SCHOOL: route(
      "login-school",
      {
        searchParams: {
          suggestedProvider: string().default("")
        },
        state: {
          redirectAuthenticatedUserToLocation: type(toValidator)
        }
      },
      {
        USER_DETAILS: route("user-details"),
        CHECK_YOUR_EMAIL: route("check-your-email", {
          state: {
            email: string().default(""),
            givenName: string(),
            familyName: string()
          }
        })
      }
    ),
    SIGN_IN_PARENT_VIA_LINK: route("sign-in-parent-via-link", {
      searchParams: {
        connectionKey: string(),
        pageReferrer: string()
      }
    }),
    SIGN_IN_CHECK_YOUR_EMAIL: route("sign-in-check-your-email", {
      state: {
        email: string(),
        redirectAuthenticatedUserToLocation: string(),
        signInLocation: optionalLocationType
      }
    }),
    SIGN_IN_VIA_LINK: route("sign-in-via-link", {
      searchParams: {
        redirectAuthenticatedUserToLocation: string(),
        pageReferrer: string()
      }
    })
  }
);

export const MODAL_ROUTES = route(
  "",
  { state: { background: locationType } },
  {
    MIS_CLASS_TRIGGER: route("sync-information"),
    INVITE_PARENT: route("invite-parent"),
    SHARE_ASSIGNMENT: route("share-assignment", {
      searchParams: {
        classId: string(),
        assignmentId: string().default(""),
        courseId: string()
      },
      state: {
        courseName: string().default(""),
        dueDate: string()
      }
    }),
    MANAGE_STUDENT: route("manage-student", {
      searchParams: {
        classId: string().default(""),
        userId: string().default("")
      },
      state: { source: string().default("") }
    }),
    ASSIGNMENT_STUDENT_RESULT: route("student-assignment-result", {
      searchParams: {
        classId: string().default(""),
        assignmentId: string().default(""),
        userId: string().default("")
      },
      state: {
        orderedAssigneeIds: string().default("").array(),
        defaultTableMode: assignmentTableModeType
      }
    }),
    DELETE_CLASS_ASSIGNMENT: route("delete-class-assignment", {
      searchParams: {
        classId: string().default(""),
        assignmentId: string().default("")
      }
    }),
    DELETE_CLASS_ASSIGNMENTS: route("delete-class-assignments", {
      searchParams: {
        assignmentId: string().default(""),
        classId: string()
      },
      state: {
        className: string()
      }
    }),
    EDIT_MULTI_CLASS_ASSIGNMENT: route("edit-multi-class-assignment", {
      searchParams: {
        classId: string().default(""),
        assignmentId: string().default("")
      }
    }),
    START_SPEEDY_MARKING: route("start-speedy-marking", {
      searchParams: {
        classId: string().default(""),
        assignmentId: string().default("")
      },
      state: {
        selectedQuestionIds: string().default("").array()
      }
    }),
    MARKING: route("marking", {
      searchParams: {
        classId: string().default(""),
        assignmentId: string().default(""),
        userId: string().default(""),
        questionId: string().default("")
      },
      state: {
        orderedAssigneeIds: string().default("").array()
      }
    }),
    INSIGHTS_SECTIONS_SELECTOR: route("grades-section-selector", {
      searchParams: { classId: string().default("") }
    }),
    REMOVE_COURSE: route("remove-course", {
      searchParams: {
        classId: string().default(""),
        courseId: string().default("")
      }
    }),
    CHOOSE_REGION: route("choose-region"),
    INVITE_PARENT_INFO: route("invite-parent-info", {
      state: {
        classIds: string().defined().array(),
        analyticsSource: schoolQuoteSourcesType
      }
    }),
    INVITE_CLASSES_PARENTS: route("classes-invite-parents", {
      state: {
        classIds: string().defined().array(),
        ineligibleClassIds: string().defined().array(),
        analyticsSource: schoolQuoteSourcesType
      }
    }),
    INVITE_OTHER_CLASSES_PARENTS: route("invite-other-classes-parents", {
      state: {
        classIds: string().defined().array(),
        ineligibleClassIds: string().defined().array(),
        analyticsSource: schoolQuoteSourcesType
      }
    }),
    CHANGE_EMAIL: route("change-email"),
    DELETE_CLASS: route("delete-class", {
      searchParams: { classId: string().default("") }
    }),
    LEAVE_CLASS: route("leave-class", {
      searchParams: { classId: string().default("") }
    }),
    ARCHIVE_CLASSES: route("archive-classes", {
      state: { classIds: string().default("").array() }
    }),
    HIDE_CLASSES: route("hide-classes", {
      state: { classIds: string().default("").array() }
    }),
    UNHIDE_CLASSES: route("unhide-classes", {
      state: { classIds: string().default("").array() }
    }),
    PROGRESS_REPORT: route("teacher-custom-report", {
      searchParams: {
        requestId: string().default("")
      }
    }),
    JOIN_CLASS_STUDENT: route("join-class-student"),
    INVITE_TEACHER_STUDENT: route("invite-teacher-student"),
    CLASS_DOES_NOT_EXIST: route("class-does-not-exist"),
    MANAGE_TEACHERS: route("manage-teachers", {
      searchParams: { classId: string().default("") }
    }),
    TRANSFER_OWNERSHIP: route("transfer-ownership", {
      searchParams: {
        classId: string().default(""),
        userId: string().default("")
      }
    }),
    REMOVE_TEACHER: route("remove-teacher", {
      searchParams: {
        classId: string().default(""),
        userId: string().default("")
      }
    }),
    RESET_PASSWORDS_SCHOOL: route("reset-passwords-school"),
    JOIN_SCHOOL_CLASS: route("join-school-class", {
      searchParams: {
        classId: string().default(""),
        schoolId: string().default("")
      }
    }),
    JOIN_SCHOOL_CLASSES: route("join-school-classes", {
      state: {
        classIds: string().default("").array(),
        schoolId: string().default("")
      }
    }),
    CLASS_STUDENT_INVITE_SUCCESS: route("class-student-invite-success", {
      state: {
        isDirectlyAccessible: boolean(),
        enableInviteOtherClasses: boolean().default(false)
      }
    }),
    SCHOOL_RENEWAL_FORM_SUCCESS: route("school-renewal-form-success", {
      state: { contactEmail: string().default("") }
    }),
    MIS_EXPIRY: route("mis-expiry", {
      state: {
        gatingEvent: TeacherPlatformGatingEventRoutingType,
        redirectTo: locationType
      }
    }),
    REVIEW_MCA_MARKING: route("review-mca-marking", {
      state: {
        classId: string().default(""),
        assignmentId: string().default("")
      }
    }),
    ENROLL: route("start/:courseId", {
      params: { courseId: string().default("") }
    }),
    SHARE_SECTION: route("share-section", {
      state: {
        courseId: string().default(""),
        sectionId: string().default("")
      }
    }),
    GET_QUIZ_MODE: route("get-quiz-mode", {
      state: {
        courseId: string().default(""),
        sectionId: string().default("")
      }
    }),
    GET_WRONG_ANSWERS_MODE: route(
      "get-wrong-answers",
      {
        state: {
          courseId: string().default(""),
          sectionId: string().default(""),
          wrongAnswersCount: number(),
          trialJustGranted: boolean().default(false)
        }
      },
      {
        TRIAL: route("trial")
      }
    ),
    INVITE_PARENTS_EXAM_QUESTIONS: route("invite-parents-exam-questions", {
      state: {
        courseId: string().default("")
      }
    }),
    INVITE_PARENTS_WRONG_ANSWERS: route("invite-parents-wrong-answers", {
      state: {
        courseId: string().default(""),
        sectionId: string().default("")
      }
    }),
    DOWNLOAD_NOTES: route("download-notes", {
      searchParams: {
        courseId: string().default(""),
        sectionId: string().default("")
      }
    }),
    QUIZ_UPDATED: route("quiz-updated", {
      state: {
        quizId: string().default(""),
        isPublished: boolean().default(false)
      }
    }),
    QUIZ_DELETED: route("deleted"),
    QUIZ_PREMIUM_TRIGGER: route("premium", {
      state: {
        analyticsSource: schoolQuoteSourcesType
      }
    }),
    QUIZ_SHARE_SYNCED_GATING: route("quiz-share-synced", {
      state: {
        analyticsSource: schoolQuoteSourcesType,
        isResourceHubSharing: boolean().default(false)
      }
    }),
    SHARE_QUIZZES_WITH_TEACHERS: route("share-quizzes-with-teachers", {
      state: {
        quizId: string(),
        name: string(),
        shareSource: shareSourceType
      }
    }),
    SECTION_PREVIEW: route("section-preview", {
      state: {
        courseId: string().default(""),
        sectionId: string().default(""),
        message: string(),
        alert: string(),
        sectionContentType: sectionContentTypeType
      }
    }),
    WORD_PREVIEW: route("word-preview", {
      state: {
        wordId: string().default(""),
        courseId: string().default("")
      }
    }),
    PREMIUM_COURSES: route("premium-courses", {
      state: {
        source: courseAddedLocationType
      }
    }),
    ADD_COURSE_TO_CLASS: route("add-course-to-class", {
      searchParams: {
        classId: string().default(""),
        courseId: string().default("")
      },
      state: {
        source: courseAddedLocationType
      }
    }),
    CHILD_SWITCHER: route("child-switcher", {}),
    TRANSFER_SUBSCRIPTION: route("transfer-subscription", {}),
    PARENT_INVITE_CHILDREN: route("invite-children", {}),
    INVITE_TO_CLASS: route("invite-to-class", {
      searchParams: { classId: string().default("") }
    }),
    UNARCHIVE_CLASSES: route("unarchive-classes", {
      state: { classIds: string().default("").array() }
    }),
    CREATE_CLASS: route("create-class"),
    INVITE_STUDENTS_QR_CODE: route("invite-students-qr-code", {
      searchParams: {
        classId: string().default("")
      },
      state: {
        lastLocation: locationType
      }
    }),
    END_QUIZ_SESSION: route(
      "end-quiz-session/:quizId",
      {
        params: { quizId: string().default("") },
        state: {
          quizSessionData: type(anyValidator),
          assignmentId: string().default(""),
          completesAssignment: boolean().default(false),
          quizXpData: type(anyValidator),
          dailyXpData: type(anyValidator)
        }
      },
      {
        SUMMARY: route("summary-overview"),
        ASSIGNMENT_COMPLETE: route("assignment-complete"),
        SET_DAILY_XP_GOAL: route(
          "set-daily-xp",
          {},
          {
            SUCCESS: route("success")
          }
        )
      }
    ),
    QUIT_QUIZ_SESSION: route("quit-quiz-session", {
      state: {
        quitSessionPath: string().default("")
      }
    }),
    QUIT_SESSION: route("quit-session", {
      state: {
        destination: locationType
      }
    }),
    END_VOCABULARY_SESSION: route(
      "end-vocabulary-session",
      {
        state: {
          sessionId: string().default(""),
          skipAnimation: boolean().default(false)
        }
      },
      {
        ASSIGNMENT_PROGRESS: route("assignment-progress"),
        ASSIGNMENT_COMPLETE: route("assignment-complete")
      }
    ),
    PARENT_PLATFORM_PREVIEW: route("parent-platform-preview", {
      state: {
        source: parentPlatformPreviewSourceType
      }
    }),
    COMPLETE_STUDENT_ACCOUNT: route("complete-student-account"),
    GATE_AMELIA_SECTION: route("premium-amelia-section"),
    GATE_MULTI_SECTION_WRONG_ANSWER: route(
      "premium-multi-section-wrong-answers",
      {
        state: {
          source: string().default("")
        }
      }
    ),
    QUIZ_PREVIEW_MODAL: route("quiz-preview", {
      state: {
        quizId: string().default("")
      }
    }),
    ASSIGN_UNPUBLISHED_QUIZ_MODAL: route("assign-unpublished-quiz", {
      state: {
        quizId: string().default("")
      }
    }),
    PARENT_MARKETING: route("parent-marketing-preferences"),
    CLAIM_TUTORING_TRIAL: route("claim-tutoring-trial"),
    COMPLETE_TEACHER_ACCOUNT: route("complete-teacher-account"),
    FEEDBACK: route("feedback"),
    MIS_INFO: route("mis-info"),
    UNMANAGED_USER_IN_SYNCED_SCHOOL: route("chosen-school-is-synced"),
    TEACHER_PARENT_INVITE: route(
      "teacher-parent-invite",
      {
        state: {
          yearGroups: numberArrayType,
          headOfTeacher: boolean()
        }
      },
      {
        PLATFORM_DEMO: route("platform-demo"),
        DATA_PROCESSING: route("data-processing"),
        SELECT_YEAR_GROUPS: route("select-year-groups"),
        SELECT_CLASSES: route("select-classes", {
          state: {
            assignedClassIds: string().defined().array()
          }
        }),
        PARENTS_PREVIEW: route("parents-preview", {
          state: { actionable: boolean().default(true) }
        })
      }
    ),
    STUDENT_ASSIGNMENT_NOTIFICATIONS: route(
      "student-assignment-notifications",
      {
        state: { background: locationType }
      }
    ),
    TUTORING_FORM_JOURNEY: route("tutoring-form-journey", {
      searchParams: {
        step: tutoringFormModalStepType.defined(),
        oneToOneTutoringSource: oneToOneTutoringSourceType.default(
          TUTORING_LEAD_SOURCES.UNSET
        )
      },
      state: {
        childId: string().default(""),
        background: locationType
      }
    }),
    SHARE_RESOURCE: route("share-resource", {
      searchParams: {
        resourceId: string().default("")
      }
    }),
    HARDEST_QUESTIONS_GATING: route("hardest-questions-gating", {
      state: {
        courseId: string().default(""),
        sectionId: string().default(""),
        assignmentId: string()
      }
    }),
    HARDEST_QUESTIONS_TEACHER_GATING: route("hardest-questions-teacher-gating"),
    COURSE_VOCABULARY_UNLINK_WORD: route("course-vocab-unlink-word", {
      searchParams: {
        wordId: string().default(""),
        word: string().default(""),
        courseId: string().default("")
      }
    }),
    MULTI_CLASS_ASSIGNMENTS_GATING: route("unlock-multi-class-assignments"),
    CONFIRM_REMOVE_STUDENTS: route("confirm-remove-students", {
      state: {
        selectedIds: string().defined().array(),
        classId: string().default("")
      }
    }),
    CHANGE_AVATAR: route("change-avatar"),
    PAYMENT_DETAILS: route("payment-details/:subscriptionId", {
      params: { subscriptionId: string().default("") }
    }),
    SUBSCRIPTION_CANCEL: route("subscription-cancel/:subscriptionId", {
      params: { subscriptionId: string().default("") }
    }),
    SUBSCRIPTION_RESTART: route("subscription-restart/:subscriptionId", {
      params: { subscriptionId: string().default("") }
    }),
    REMOVE_PARENT: route("remove-parent/:parentId", {
      params: { parentId: string().default("") }
    }),
    ADD_PARENT: route("add-parent/:code", {
      params: { code: string().default("") }
    }),
    REMOVE_CHILD: route("disconnect-child/:childId", {
      params: { childId: string().default("") }
    }),
    UPGRADE: route(
      "upgrade",
      {
        searchParams: {
          iosSubscriptionFlow: iosSubscriptionFlowType,
          userType: userAccountTypeType,
          userId: string(),
          openLinkInBrowserForIosApp: boolean()
        }
      },
      {
        SUBSCRIPTION: route(
          ":subscriptionId",
          {
            params: { subscriptionId: string().defined() }
          },
          {
            PREVIEW: route("preview"),
            CARD_DETAILS: route("card-details"),
            AUTHENTICATION: route("authentication"),
            RETRY_PAYMENT: route("retry-payment"),
            SUCCESS: route("success", {
              state: { newSubscriptionProductId: string().default("") }
            })
          }
        ),
        UNAVAILABLE: route("unavailable"),
        SUBSCRIPTION_INFO_IOS: route("upgrade-subscription-info-ios")
      }
    ),
    TEACHER: route(
      "teacher",
      {},
      {
        PARENT_AUTO_INVITES: route(
          "parent-auto-invites",
          {},
          {
            PREVIEW: route("preview", {
              state: { isDirectlyAccessible: boolean() }
            }),
            SELECT_YEAR_GROUP: route("select-year-group", {
              state: {
                isDirectlyAccessible: boolean(),
                flow: parentAutoInvitesFlowType.default("postInvite")
              }
            }),
            SUCCESS: route("success", {
              state: {
                isDirectlyAccessible: boolean(),
                selectedYearGroups: number().defined().array(),
                flow: parentAutoInvitesFlowType.default("postInvite")
              }
            })
          }
        )
      }
    ),
    GENERATE_CUSTOM_REPORT: route(
      "generate-custom-report",
      {},
      {
        TYPE: route("type"),
        DETAILS: route("details")
      }
    ),
    UPDATE_APP: route("update-app", {
      state: {
        background: locationType,
        reason: string().default("")
      }
    }),
    EXAM_QUESTIONS_INFO: route("exam-questions-info"),
    MAGIC_QUIZ_INFO: route("magic-quiz-info"),
    CONNECT_MICROSOFT_ACCOUNT: route("connect-microsoft-account", {
      state: { background: locationType }
    }),
    SET_DAILY_XP_GOAL: route("set-daily-xp"),
    END_SESSION: route(
      "end-session",
      {},
      {
        COURSE: route(
          "course/:courseId",
          { params: { courseId: string().default("") } },
          {
            SECTION: route(
              "section/:sectionId",
              { params: { sectionId: string().default("") } },
              {
                LEVEL_UP: route("level-up"),
                REWARD: route("reward"),
                CLASS_LEADERBOARDS: route("class-leaderboards"),
                AWARD_MEMORY: route("store-your-memory"),
                VIEW_MEMORY: route("view-memory"),
                ASSIGNMENT_COMPLETE: route("assignment-complete"),
                ASSIGNMENT_PROGRESS: route("assignment-progress"),
                WRONG_ANSWERS: route("wrong-answers"),
                DAILY_XP: route(
                  "daily-xp",
                  {},
                  {
                    SET: route("set"),
                    SELECT: route(
                      "select",
                      {},
                      {
                        SUCCESS: route("success")
                      }
                    ),
                    PROGRESS: route("progress")
                  }
                ),
                CHALLENGE: route(
                  "challenge",
                  {},
                  {
                    SHARE: route("share"),
                    RESULT: route("result"),
                    SHARE_RESULT: route("share-result")
                  }
                ),
                ASK_AMELIA: route("ask-amelia"),
                MINI_MOCK: route("mini-mock"),
                EXAM_QUESTIONS: route("exam-questions"),
                PAST_PAPERS: route("past-papers"),
                PARENT_INVITE: route("parent-invite"),
                SESSIONS_MILESTONE: route("sessions-milestone"),
                PARENT_INVITE_CONFIRMATION: route("parent-invite-confirmation"),
                STUDENT_SENTIMENT: route("student-sentiment")
              }
            )
          }
        )
      }
    )
  }
);
