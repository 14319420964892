import { remove as removeDiacritics } from "diacritics";
import lev from "js-levenshtein";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import memoize from "lodash/memoize";
// TODO: break up utils so not every util has dependancy on all of the below:
import random from "lodash/random";
import throttle from "lodash/throttle";

import { NUMBERS_TO_WORDS } from "seneca-common/constants";

import shuffle from "./random/shuffle";

export { allValuesAreTheSame } from "./allValuesAreTheSame";
export { isEqual } from "./isEqual";
export { default as removeUndefinedKeys } from "./removeUndefinedKeys";
export { cloneDeep, debounce, memoize, random, throttle };

export function isIn(value: any, object: Record<string, any>): boolean {
  for (let key in object) {
    if (object[key] === value) return true;
  }

  return false;
}

export function chooseMFromN(m: number, n: number): Array<number> {
  const chosen: number[] = [];
  if (n === 0) return chosen;
  if (m > n) m = n;

  for (let i = n - m; i < n; i++) {
    const rand = random(0, i);

    if (!chosen.includes(rand)) {
      chosen.push(rand);
    } else {
      chosen.push(i);
    }
  }

  return chosen;
}

export function chooseMFromNShuffle(m: number, n: number): Array<number> {
  return shuffle(chooseMFromN(m, n));
}

export const removeSpaces = (str: string): string => str.replace(/\s+/g, "");

export const getDimensionFromCSSVar = (
  str: string = "0px",
  suffix: string = "px"
) => Number(str.slice(0, str.length - suffix.length));

export const getDurationFromCSSvar = (str: string) => {
  if (!str) return 0;

  if (str.endsWith("ms")) {
    return Number(str.substring(0, str.length - 2));
  } else if (str.endsWith("s")) {
    return 1000 * Number(str.substring(0, str.length - 1));
  }

  return 0;
};

export function compareWithUndefinedLast<T extends any>(
  bothExistCompare: (arg0: T, arg1: T) => number,
  a: T,
  b: T
): number {
  const isValueUndefined = (value: any) =>
    value === undefined || value === null || value === "<Unknown>";

  if (!isValueUndefined(a) && !isValueUndefined(b)) {
    return bothExistCompare(a, b);
  } else if (isValueUndefined(a) && !isValueUndefined(b)) {
    return 1;
  } else if (!isValueUndefined(a) && isValueUndefined(b)) {
    return -1;
  } else {
    return 0;
  }
}

type GenerationFunction = (arg0: void) => boolean;

export const makeGenerationFunction =
  (probability: number): GenerationFunction =>
  () =>
    Math.random() < probability;

export const isValidNumber = (num: any): num is number => {
  return (
    num !== undefined &&
    num !== null &&
    !Number.isNaN(Number(num)) &&
    typeof num === "number"
  );
};

export const stringIsValidNumber = (num: string): boolean => {
  return (
    num !== undefined &&
    num !== null &&
    num !== "" &&
    !Number.isNaN(Number(num))
  );
};

export const isValidBoolean = (bool?: boolean | null): boolean => {
  return bool !== undefined && bool !== null && typeof bool === "boolean";
};

export const CHECK_WORD_AGAINST_ANSWER_TOLERANCE = 6;

export const checkWordAgainstAnswer = (
  word: string,
  attempt: string,
  noTolerance?: boolean
): boolean => {
  if (!word || !attempt) return false;

  const wordIsNumber = stringIsNumber(word);
  const attemptIsNumber = stringIsNumber(attempt);

  const formattedWord = formatWordForCheckingAgainstAnswer(word);
  const formattedAttempt = formatWordForCheckingAgainstAnswer(attempt);

  if (noTolerance) {
    return formattedWord === formattedAttempt;
  }

  if (wordIsNumber && attemptIsNumber) {
    return convertToNumber(word) === convertToNumber(attempt);
  }

  if (wordIsNumber && !!getPossibleWordsForNumber(convertToNumber(word))) {
    return getPossibleWordsForNumber(convertToNumber(word)).some(
      possibleWord => {
        // call recursively to get lev checking
        return checkWordAgainstAnswer(possibleWord, formattedAttempt);
      }
    );
  }

  if (
    attemptIsNumber &&
    !!getPossibleWordsForNumber(convertToNumber(attempt))
  ) {
    return getPossibleWordsForNumber(convertToNumber(attempt)).includes(
      formattedWord
    );
  }

  // Prevents overzealous answering if all characters are correct so far
  if (
    formattedWord.charAt(formattedWord.length - 1) !== "s" &&
    formattedAttempt.length < formattedWord.length &&
    formattedAttempt === formattedWord.slice(0, formattedAttempt.length)
  ) {
    return false;
  }

  const tolerance =
    formattedAttempt.length / CHECK_WORD_AGAINST_ANSWER_TOLERANCE;
  const distance = lev(formattedWord, formattedAttempt);

  let isWithinTolerance = !!formattedWord && distance <= tolerance;

  // If word is not within tolerance and contains diacritics check attempt
  // against word without diacritics. All accented chars are mapped to ASCII chars.
  if (!isWithinTolerance) {
    const formattedWordWithoutDiacritics = removeDiacritics(formattedWord);

    if (formattedWordWithoutDiacritics !== formattedWord) {
      const distanceWithoutDiacritics = lev(
        formattedWordWithoutDiacritics,
        formattedAttempt
      );
      isWithinTolerance =
        !!formattedWord && distanceWithoutDiacritics <= tolerance;
    }
  }

  return isWithinTolerance;
};

export const stringIsNumber = (possibleNumber: string): boolean =>
  possibleNumber.search(/[^0-9,.-]/g) === -1 &&
  possibleNumber.search(/[^0-9]$/g) === -1 &&
  !isNaN(convertToNumber(possibleNumber));

export const convertToNumber = (numberAsString: string): number =>
  parseFloat(numberAsString.replace(/,/g, ""));

export const getPossibleWordsForNumber = (number: number): string[] =>
  (NUMBERS_TO_WORDS as any)[number];

const germanCharSet = `äöüß`;
const frenchCharSet = `çéâêîôûàèùëïüœ`;
const spanishCharSet = `áéíóúüñã`;
const greekCharSet = `\u0370-\u03ff\u1f00-\u1fff`;
export const accentedCharSet = `${germanCharSet}${frenchCharSet}${spanishCharSet}${greekCharSet}`;
const alphaNumCharSet = `a-zA-Z0-9`;
const otherCharSet = `+-`;
export const keepCharSet = `${alphaNumCharSet}${accentedCharSet}${otherCharSet}`;

// the `g` flag for global search, the `i` flag for case-insensitivity
const notAlphaNumAndAccents = new RegExp(
  `<sub>|</sub>|<sup>|</sup>|[^${keepCharSet}]`,
  `gi`
);

export const formatWordForCheckingAgainstAnswer = (ans: string = "") =>
  ans.replace(notAlphaNumAndAccents, "").toLowerCase().trim();

export const convertInputToNumber0To1 = (input: string | number): number => {
  if (!input) {
    return 0;
  }

  // If a number is sent perform operation to try and ensure consecutive numbers don't return the same output
  if (isValidNumber(input)) {
    input = String(Math.exp((input as number) % 500)); // % 500 to ensure we don't return 'infinity'
  }

  // Loop over all charaters of the string and multiply the unicode values
  const inputNumber = (input as string)
    .split("")
    .reduce((total, char) => total * (char.charCodeAt(0) || 1), 1);

  // normalise
  // Math.sin gives psuedo random output but is slightly biased to 0 and 1
  const valueFrom0to1 = (Math.sin(inputNumber) + 1) / 2;

  return valueFrom0to1;
};

export const getRandomItemFromArray = <T>(array: Array<T>): T | undefined =>
  array[Math.floor(Math.random() * array.length)];

export const splitStringIntoNumbersAndText = (
  str: string
): Array<number | string> | null | undefined => {
  if (!str && !isValidNumber(str)) return null;

  if (isValidNumber(str)) return [str];

  return str
    .split(/-?(\d+(\.\d+)?)/) // Will split '123.456h' -> ['123.456', '.456', 'h']
    .filter(Boolean) // Filter out empty strings
    .filter(x => !x.startsWith(".")) // Filter out double counted '.456' values
    .map(x => (isValidNumber(Number(x)) ? Number(x) : x)); // cast to numbers
};

export const capitaliseFirstCharacterOfString = (
  s: string | null | undefined
): string => (s ? s[0].toUpperCase() + s.slice(1) : "");

export const countDecimals = (value: number): number => {
  if (Math.floor(value) !== value)
    return value.toString().split(".")[1].length || 0;
  return 0;
};

export const objectHasProperty = (o: any, property: string): boolean =>
  typeof o === "object" && o.hasOwnProperty(property);

export const getNRandomElementsFromArray = <T>(n: number, arr: T[]): T[] => {
  const chosen = chooseMFromNShuffle(n, arr.length);
  return arr.filter((value: T, i: number): boolean => chosen.includes(i));
};

export const getRandomElementFromArray = <T>(arr: T[]): T =>
  arr[Math.floor(Math.random() * arr.length)];

export function roundTo(numberOfDecimalPlaces: number) {
  return {
    decimalPlaces: (num: number) => {
      const multiplier = Math.pow(10, numberOfDecimalPlaces);
      return Math.round(num * multiplier) / multiplier;
    }
  };
}
