import useSavePushNotificationTokeniOS from "services/firebase/messaging-ios/hooks/useSavePushNotificationToken";

import { useFeatureFlag } from "features/feature-flags";
import { useSetAndroidTWA } from "features/pwa/mobile/android/hooks/useAndroidTWA";
import { useSetAndroidTWAVersion } from "features/pwa/mobile/android/hooks/useAndroidTWAVersion";

import useSendRegionMessageiOS from "./ios/hooks/useSendRegionMessage";
import { usePromptToUseApp } from "./prompt-to-use-app/hooks/usePromptToUseApp";
import { PromptToUseApp } from "./prompt-to-use-app/PromptToUseApp";

type Props = {
  children: React.ReactElement;
};

export default function PWAMobileProvider({ children }: Props) {
  useSetAndroidTWA();
  useSetAndroidTWAVersion();
  useSendRegionMessageiOS();
  useSavePushNotificationTokeniOS();

  const { canShow, prompt, isStudent, setHasBeenInteractedWith } =
    usePromptToUseApp();

  const showPromptsToUseApp = useFeatureFlag("showPromptsToUseApp");

  return (
    <>
      {canShow && showPromptsToUseApp && (
        <PromptToUseApp
          prompt={prompt}
          isStudent={isStudent}
          setHasBeenInteractedWith={setHasBeenInteractedWith}
        />
      )}
      {children}
    </>
  );
}
