import { useEffect, useState } from "react";

import {
  LEVELS_V1_AB_FLAG,
  USER_LEVEL_VARIANT_TEST_ID
} from "seneca-common/features/levels/featureFlag";

import {
  logUserAssignedToVariant,
  useFeatureFlag
} from "features/feature-flags";

export const END_SESSION_FLOW_TEST_ID = "endSessionFlow";

export default function useEndSessionFlowFlag() {
  const flag = useFeatureFlag(END_SESSION_FLOW_TEST_ID);

  const [loggedAnalytics, setLoggedAnalytics] = useState(false);
  useEffect(() => {
    if (loggedAnalytics) return;
    if (!flag) return;

    logUserAssignedToVariant({
      testId: END_SESSION_FLOW_TEST_ID,
      variant: flag
    });

    logUserAssignedToVariant({
      testId: USER_LEVEL_VARIANT_TEST_ID,
      variant: LEVELS_V1_AB_FLAG
    });

    setLoggedAnalytics(true);
  }, [flag, loggedAnalytics]);

  return !!flag;
}
