import {
  analyticsLogPromptToUseAppBannerCtaClicked,
  analyticsLogPromptToUseAppBannerDismissed,
  analyticsLogPromptToUseAppModalCtaClicked,
  analyticsLogPromptToUseAppModalDismissed
} from "features/app/pwa/analytics";

import { Prompt } from "./types";

const PREFIX = "prompt-to-use-app-v2:";

const PROMPT_TO_USE_APP_STORAGE_KEYS = {
  session: {
    hasBeenInteractedWithKey: `${PREFIX}has-been-interacted-with`,
    bannerHasBeenViewedKey: `${PREFIX}banner-has-been-viewed`
  },
  local: {
    timesHasBeenInteractedWithKey: `${PREFIX}times-has-been-interacted-with`
  }
};

export function getHasBeenInteractedWithInSession() {
  const hasBeenInteractedWithValue = window.sessionStorage.getItem(
    PROMPT_TO_USE_APP_STORAGE_KEYS.session.hasBeenInteractedWithKey
  ) as "true" | null;

  return hasBeenInteractedWithValue === "true";
}

export function setHasBeenInteractedWithInSession() {
  window.sessionStorage.setItem(
    PROMPT_TO_USE_APP_STORAGE_KEYS.session.hasBeenInteractedWithKey,
    "true"
  );
}

export function getBannerHasBeenViewedInSession() {
  const bannerHasBeenViewedValue = window.sessionStorage.getItem(
    PROMPT_TO_USE_APP_STORAGE_KEYS.session.bannerHasBeenViewedKey
  ) as "true" | null;

  return bannerHasBeenViewedValue === "true";
}

export function setBannerHasBeenViewedInSession() {
  window.sessionStorage.setItem(
    PROMPT_TO_USE_APP_STORAGE_KEYS.session.bannerHasBeenViewedKey,
    "true"
  );
}

export function getTimesHasBeenInteractedWithLocally() {
  const timesHasBeenInteractedWithValue = window.localStorage.getItem(
    PROMPT_TO_USE_APP_STORAGE_KEYS.local.timesHasBeenInteractedWithKey
  ) as string | null;

  return timesHasBeenInteractedWithValue === null
    ? 0
    : parseInt(timesHasBeenInteractedWithValue);
}

function setTimesHasBeenInteractedWithLocally(value: number) {
  window.localStorage.setItem(
    PROMPT_TO_USE_APP_STORAGE_KEYS.local.timesHasBeenInteractedWithKey,
    value.toString()
  );
}

export function incrementTimesHasBeenInteractedWithLocally() {
  const timesHasBeenInteractedWith = getTimesHasBeenInteractedWithLocally();
  setTimesHasBeenInteractedWithLocally(timesHasBeenInteractedWith + 1);
}

export function logDismissed(prompt: Prompt) {
  if (prompt === "modal") {
    analyticsLogPromptToUseAppModalDismissed();
  } else {
    analyticsLogPromptToUseAppBannerDismissed();
  }
}

export function logCtaClicked(prompt: Prompt) {
  if (prompt === "modal") {
    analyticsLogPromptToUseAppModalCtaClicked();
  } else {
    analyticsLogPromptToUseAppBannerCtaClicked();
  }
}
