import ReducerRegistry from "seneca-common/utils/state/reducer-registry";

import { PATH_TO_SUBSCRIPTION_PRODUCTS_STATE } from "./consts";
import subscriptionProductsReducer from "./reducers";

const reducerRegistry = ReducerRegistry.getInstance();
reducerRegistry.initialise(PATH_TO_SUBSCRIPTION_PRODUCTS_STATE, builder => {
  builder.register(
    PATH_TO_SUBSCRIPTION_PRODUCTS_STATE,
    subscriptionProductsReducer
  );
});

export { PATH_TO_SUBSCRIPTION_PRODUCTS_STATE, subscriptionProductsReducer };

// actions
export {
  receiveSubscriptionProduct,
  receiveSubscriptionProducts
} from "./actions";
export { fetchAllSubscriptionProductsActionFactory } from "./actions/meta";

// selectors
export {
  getSameTierProductAnnualPrice,
  getSameTierProductSeasonalPrice,
  getSortedAnnualSubscriptionProductIds,
  getSortedMonthlySubscriptionProductIds,
  getSubscriptionProduct,
  getSubscriptionProductCanBeUpgraded, // subscription-product
  getSubscriptionProductDescription,
  getSubscriptionProductIconUrl,
  getSubscriptionProductIsAddOn,
  getSubscriptionProductIsMisBooster, // payment-frequency
  getSubscriptionProductPaymentFrequency,
  getSubscriptionProductPrice,
  // subscription products
  getSubscriptionProducts,
  getSubscriptionTierName,
  getTotalPriceByProductIds,
  makeGetSortedPromotedPeriodSubscriptionProductIds,
  subscriptionProductExists,
  subscriptionProductHasAiAssistant,
  subscriptionProductHasAiQuizGeneration,
  subscriptionProductHasExamQuestions,
  subscriptionProductHasGuaranteedGrades,
  subscriptionProductHasParentPremiumFeatures,
  subscriptionProductHasQuizMode,
  subscriptionProductHasSmartLearningMode,
  subscriptionProductHasTutoring,
  subscriptionProductHasWrongAnswersMode,
  subscriptionProductIsBilledAnually,
  subscriptionProductIsBilledMonthly,
  subscriptionProductPaymentFrequency
} from "./selectors";

export {
  subscriptionProductsAreFetching,
  subscriptionProductsFetchErrored,
  subscriptionProductsFetchFinished,
  subscriptionProductsFetchSucceeded,
  subscriptionProductsNeedFetching
} from "./selectors/meta";

// models
export { default as Product } from "./models/state/subscription-products/subscription-product/product/Product";
export { SUBSCRIPTION_TIERS } from "./models/state/subscription-products/subscription-product/productDescription/ProductDescription";
export {
  SUBSCRIPTION_PRODUCT_PAYMENT_FREQUENCIES,
  default as SubscriptionProduct
} from "./models/state/subscription-products/subscription-product/SubscriptionProduct";
export type {
  SubscriptionProductPaymentFrequencies,
  SubscriptionProductType
} from "./models/state/subscription-products/subscription-product/SubscriptionProduct";
export { default as SubscriptionProductsStateSlice } from "./models/SubscriptionProductsStateSlice";
