import { createContext, ReactNode, useMemo, useState } from "react";

type ConfettiContext = {
  initialised: boolean;
  setInitialised: () => void;
};

const defaultValue: ConfettiContext = {
  initialised: false,
  setInitialised: () => {}
};

export const context = createContext(defaultValue);

export default function ConfettiProvider({
  children
}: {
  children: ReactNode;
}) {
  const [initialised, setInitialised] = useState(defaultValue.initialised);

  const value = useMemo(
    () => ({
      initialised,
      setInitialised: () => setInitialised(true)
    }),
    [initialised]
  );

  return <context.Provider value={value}>{children}</context.Provider>;
}
