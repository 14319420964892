// Pull in client here rather than in fetchSubscriptions to remove possible
// circular dependency if we're using the mockClient in services
// @ts-nocheck
import { getClient } from "services";

import { makeCreateSubscription } from "seneca-common/features/subscriptions/state/service/createSubscription";

import { makeCancelSubscription } from "./cancelSubscription";
import { makeConfirmSubscriptionStatus } from "./confirmSubscriptionStatus";
import {
  makeFetchMySubscriptions,
  makeFetchMySubscriptionsIfNotFetched
} from "./fetchMySubscriptions";
import { makeFetchPaymentIntentClientSecret } from "./fetchPaymentIntentClientSecret";
import { makeRestartSubscription } from "./restartSubscription";
import { makeRetrySubscriptionPayment } from "./retrySubscriptionPayment";
import { makeUpdatePaymentMethod } from "./updatePaymentMethod";

const getSubscriptionServiceClient = () => getClient("subscriptionService");

export const fetchSubscriptionsIfNotFetched =
  makeFetchMySubscriptionsIfNotFetched(getSubscriptionServiceClient);

export const createSubscription = makeCreateSubscription(
  getSubscriptionServiceClient
);
export const cancelSubscription = makeCancelSubscription(
  getSubscriptionServiceClient
);

export const restartSubscription = makeRestartSubscription(
  getSubscriptionServiceClient
);

export const confirmSubscriptionStatus = makeConfirmSubscriptionStatus(
  getSubscriptionServiceClient
);

export const retrySubscriptionPayment = makeRetrySubscriptionPayment(
  getSubscriptionServiceClient
);

export const fetchPaymentIntentClientSecret =
  makeFetchPaymentIntentClientSecret(getSubscriptionServiceClient);

export const updatePaymentMethod = makeUpdatePaymentMethod(
  getSubscriptionServiceClient
);
