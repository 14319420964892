import {
  getAddonProductIds,
  getSubscriptionProductId
} from "seneca-common/features/subscriptions/state";

import * as product from "../state";

type State = any;

export const getMySubscriptionTierName: (
  state: State,
  subscriptionId: string
) => string = (state, subscriptionId) => {
  const subscriptionProductId = getSubscriptionProductId(state, subscriptionId);
  return product.getSubscriptionTierName(state, subscriptionProductId);
};

export const subscriptionProductPaymentFrequency = (
  state: State,
  subscriptionId: string
) => {
  const subscriptionProductId = getSubscriptionProductId(
    state,
    subscriptionId
  ) as string;
  return product.subscriptionProductPaymentFrequency(
    state,
    subscriptionProductId
  );
};

export const getMySubscriptionProductIconUrl = (
  state: State,
  subscriptionId: string
) => {
  const subscriptionProductId = getSubscriptionProductId(state, subscriptionId);
  return product.getSubscriptionProductIconUrl(state, subscriptionProductId!);
};

export const getSubscriptionPrice = (state: State, subscriptionId: string) => {
  const subscriptionProductId = getSubscriptionProductId(state, subscriptionId);
  return product.getSubscriptionProductPrice(state, subscriptionProductId);
};

export const subscriptionProductHasExamQuestions = (
  state: State,
  subscriptionId: string
) =>
  subscriptionProductsHasAccessToFeature(
    state,
    subscriptionId,
    product.subscriptionProductHasExamQuestions
  );

export const subscriptionProductHasGuaranteedGrades = (
  state: State,
  subscriptionId: string
) => {
  return subscriptionProductsHasAccessToFeature(
    state,
    subscriptionId,
    product.subscriptionProductHasGuaranteedGrades
  );
};

export const subscriptionProductHasSmartLearning = (
  state: State,
  subscriptionId: string
) => {
  return subscriptionProductsHasAccessToFeature(
    state,
    subscriptionId,
    product.subscriptionProductHasSmartLearningMode
  );
};

export const subscriptionProductHasWrongAnswers = (
  state: State,
  subscriptionId: string
) =>
  subscriptionProductsHasAccessToFeature(
    state,
    subscriptionId,
    product.subscriptionProductHasWrongAnswersMode
  );

export const subscriptionProductHasQuizMode = (
  state: State,
  subscriptionId: string
) =>
  subscriptionProductsHasAccessToFeature(
    state,
    subscriptionId,
    product.subscriptionProductHasQuizMode
  );

export const subscriptionProductHasAiAssistant = (
  state: State,
  subscriptionId: string
) =>
  subscriptionProductsHasAccessToFeature(
    state,
    subscriptionId,
    product.subscriptionProductHasAiAssistant
  );

export const subscriptionProductHasAiQuizGeneration = (
  state: State,
  subscriptionId: string
) =>
  subscriptionProductsHasAccessToFeature(
    state,
    subscriptionId,
    product.subscriptionProductHasAiQuizGeneration
  );

export const subscriptionProductHasTutoring = (
  state: State,
  subscriptionId: string
) =>
  subscriptionProductsHasAccessToFeature(
    state,
    subscriptionId,
    product.subscriptionProductHasTutoring
  );

export const subscriptionProductCanBeUpgraded = (
  state: State,
  subscriptionId: string
) =>
  subscriptionProductsHasAccessToFeature(
    state,
    subscriptionId,
    product.getSubscriptionProductCanBeUpgraded
  );

function subscriptionProductsHasAccessToFeature(
  state: State,
  subscriptionId: string,
  selector: (state: any, subscriptionProductId: string) => boolean
) {
  const subscriptionProductId = getSubscriptionProductId(state, subscriptionId);
  const addonProductIds = getAddonProductIds(state, subscriptionId);

  return [subscriptionProductId, ...addonProductIds].some(
    id => !!selector(state, id)
  );
}

export const getAddonGuaranteeProductId = (
  state: State,
  subscriptionId: string
) => {
  const addonProductIds = getAddonProductIds(state, subscriptionId);
  return addonProductIds.find(id =>
    product.subscriptionProductHasGuaranteedGrades(state, id)
  );
};
