import { lazy, Suspense, useCallback } from "react";

import loaderWithRetry from "seneca-common/utils/loaderWithRetry";
import { openWindowSecurely } from "seneca-common/utils/routing";

import { isSafariIos } from "features/common/utils/functions/browser";
import { isAndroid, isIOS } from "features/common/utils/functions/mobile";

import { PromptToUseAppBanner } from "./banner/PromptToUseAppBanner";
import { PromptToUseAppModal } from "./modal/PromptToUseAppModal";
import { Prompt, PromptProps } from "./types";
import {
  incrementTimesHasBeenInteractedWithLocally,
  logCtaClicked,
  logDismissed,
  setHasBeenInteractedWithInSession
} from "./utils";

const StudentPromptToUseAppModal = lazy(() =>
  loaderWithRetry(
    () =>
      import(
        /* webpackChunkName: "student-prompt-to-use-app-modal" */
        "./modal/StudentPromptToUseAppModal"
      )
  )
);

type Props = {
  prompt: Prompt;
  isStudent: boolean;
  setHasBeenInteractedWith: () => void;
};

export function PromptToUseApp({
  prompt,
  isStudent,
  setHasBeenInteractedWith
}: Props) {
  const registerInteraction = useCallback(() => {
    setHasBeenInteractedWith();
    setHasBeenInteractedWithInSession();
    incrementTimesHasBeenInteractedWithLocally();
  }, [setHasBeenInteractedWith]);

  const handleDismiss = useCallback(() => {
    registerInteraction();
    logDismissed(prompt);
  }, [registerInteraction, prompt]);

  const handleCtaClick = useCallback(() => {
    registerInteraction();
    logCtaClicked(prompt);

    if (isIOS()) {
      openWindowSecurely("https://apps.apple.com/uk/app/seneca/id1631302657");
    } else if (isAndroid()) {
      openWindowSecurely(
        "https://play.google.com/store/apps/details?id=com.senecalearning.app"
      );
    }
  }, [registerInteraction, prompt]);

  const props: PromptProps = {
    handleDismiss,
    handleCtaClick
  };

  if (prompt === "banner" && isSafariIos()) {
    // Safari on iOS shows a native Universal Links banner
    return null;
  }

  if (prompt === "modal") {
    return isStudent ? (
      <Suspense>
        <StudentPromptToUseAppModal {...props} />
      </Suspense>
    ) : (
      <PromptToUseAppModal {...props} />
    );
  }

  return <PromptToUseAppBanner {...props} />;
}
