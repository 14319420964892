import { linkToHelpSection } from "common/links";

export function linkToDashboard() {
  return `/dashboard`;
}

export function linkToJoinClassById(classId = ":classId"): string {
  return `${linkToDashboard()}/join-class/${classId}`;
}

export function linkToReceiveGiftModal() {
  return `${linkToDashboard()}/receive-gift`;
}

export function linkToReceiveGiftModalWithCode(code: string) {
  return `${linkToReceiveGiftModal()}?code=${code}`;
}

export function linkToInviteToDashboardLeaderboard() {
  return `${linkToDashboard()}/leaderboard-invite`;
}

export const linkToDashboardYourDetailsForm = () =>
  `${linkToDashboard()}/your-details`;

export function linkToStudentHelpSection(locale: string) {
  return `${linkToHelpSection(locale)}/collections/1433510-student-faqs`;
}

export function linkToPremiumUpgradeHelp(locale: string) {
  return `${linkToHelpSection(
    locale
  )}/articles/3462288-upgrade-or-renew-your-premium-plan`;
}

export function linkToWhatsappShare() {
  return `https://wa.me/?text=I've%20been%20enjoying%20studying%20with%20Seneca%20recently.%20You%20can%20check%20out%20my%20scores%20by%20creating%20a%20free%20parent%20account%20here:%20https://bit.ly/join-me-on-Seneca`;
}

export function linkToUpdateDailyXPGoal() {
  return `${linkToDashboard()}/update-daily-xp`;
}
