import { StateParamType } from "seneca-common/utils/routing/react-router-typesafe-routes/common";

import { ExpiryType } from "features/teachers/consts";

export const TeacherPlatformGatingEventRoutingType: StateParamType<
  TeacherPlatformGatingEvent,
  TeacherPlatformGatingEvent
> = {
  getPlainStateParam(value: TeacherPlatformGatingEvent) {
    return value;
  },
  getTypedStateParam(value) {
    return value as TeacherPlatformGatingEvent;
  }
};

export enum TeacherPlatformGatingEvent {
  PremiumExpiryBanner = "PremiumExpiryBanner",
  MisExpiryBanner = "MisExpiryBanner",
  SyncedClasses = "SyncedClasses",
  SchoolPage = "SchoolPage",
  UserNotManaged = "UserNotManaged",
  ReportsPage = "ReportsPage",
  MultiClassAssignments = "MultiClassAssignments",
  UnlimitedMagicQuizzes = "UnlimitedMagicQuizzes",
  SchoolSharingMagicQuizzes = "SchoolSharingMagicQuizzes",
  PrivateMagicQuizzes = "PrivateMagicQuizzes",
  SharingMagicQuizzesWithTeachers = "SharingMagicQuizzesWithTeachers",
  SharingResourcesPreviewWithTeachers = "SharingResourcesPreviewWithTeachers",
  StudentReportCards = "StudentReportCards"
}

export const TeacherPlatformGatingEventToExpiryTypeMap: Record<
  TeacherPlatformGatingEvent,
  ExpiryType
> = {
  [TeacherPlatformGatingEvent.PremiumExpiryBanner]: ExpiryType.premium,
  [TeacherPlatformGatingEvent.MisExpiryBanner]: ExpiryType.misSync,
  [TeacherPlatformGatingEvent.SyncedClasses]: ExpiryType.misSync,
  [TeacherPlatformGatingEvent.SchoolPage]: ExpiryType.misSync,
  [TeacherPlatformGatingEvent.UserNotManaged]: ExpiryType.misSync,
  [TeacherPlatformGatingEvent.ReportsPage]: ExpiryType.misSync,
  [TeacherPlatformGatingEvent.MultiClassAssignments]: ExpiryType.misSync,
  [TeacherPlatformGatingEvent.UnlimitedMagicQuizzes]: ExpiryType.premium,
  [TeacherPlatformGatingEvent.PrivateMagicQuizzes]: ExpiryType.premium,
  [TeacherPlatformGatingEvent.SchoolSharingMagicQuizzes]: ExpiryType.premium,
  [TeacherPlatformGatingEvent.SharingMagicQuizzesWithTeachers]:
    ExpiryType.misSync,
  [TeacherPlatformGatingEvent.SharingResourcesPreviewWithTeachers]:
    ExpiryType.misSync,
  [TeacherPlatformGatingEvent.StudentReportCards]: ExpiryType.misSync
};
