import { matchPath } from "react-router-dom-v5-compat";

import { MODAL_ROUTES } from "routing/routes";

export function linkToParentDashboard() {
  return "/parent";
}

export function linkToParentWhatIsPremium() {
  return "/parent/what-is-premium";
}

export function isInParentPlatform(pathname: string) {
  return matchPath(`${linkToParentDashboard()}/*`, pathname);
}

export function isInParentPremium(pathname: string) {
  return matchPath(`${linkToParentWhatIsPremium()}/*`, pathname);
}

export function isInChangeEmailModal(pathname: string) {
  return matchPath(MODAL_ROUTES.CHANGE_EMAIL.path, pathname);
}
