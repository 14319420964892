import { useState } from "react";
import { useSelector } from "react-redux";

import { useBreakpoint } from "@seneca/senekit";

import { isFirstUserSession } from "seneca-common/features/user/state";

import useAppVariant from "features/common/hooks/useAppVariant";
import { isAndroid, isIOS } from "features/common/utils/functions/mobile";
import { linkToParentDashboard } from "features/parents/links";
import { useIosSubscriptionFlowQueryParams } from "features/subscriptions/features/webview-ios/hooks/useIosSubscriptionFlow";
import { ROUTES } from "routing/routes";

import { useGetIsAndroidTWA } from "../../android/hooks/useAndroidTWA";
import { isNativeAppiOS } from "../../ios/utils/isNativeAppiOS";
import { Prompt } from "../types";
import {
  getHasBeenInteractedWithInSession,
  getTimesHasBeenInteractedWithLocally
} from "../utils";

const MAX_INTERACTIONS = 3;

export function usePromptToUseApp() {
  const { pathname } = window.location;
  const isParentRoute = pathname.includes(linkToParentDashboard());
  const isTeacherRoute = pathname.includes(ROUTES.TEACHER.path);
  const isDashboardRoute = pathname.includes(ROUTES.DASHBOARD.path);
  const valuesByVariant = {
    seneca: isParentRoute || isTeacherRoute || isDashboardRoute,
    plurall: false,
    goStudentLearning: false
  };
  const appVariant = useAppVariant();
  const canShowOnVariantAndRoute = valuesByVariant[appVariant];

  const isUsersFirstSession = useSelector(isFirstUserSession);
  const { isMobileOrTablet } = useBreakpoint();
  const { iosSubscriptionFlow } = useIosSubscriptionFlowQueryParams();

  const timesHasBeenInteractedWith = getTimesHasBeenInteractedWithLocally();

  const [hasBeenInteractedWith, setHasBeenInteractedWith] = useState(false);

  const isMobileDevice = isAndroid() || isIOS();

  const isAndroidTWA = useGetIsAndroidTWA();

  const canShow =
    canShowOnVariantAndRoute &&
    !isUsersFirstSession &&
    isMobileOrTablet &&
    !iosSubscriptionFlow &&
    timesHasBeenInteractedWith < MAX_INTERACTIONS &&
    !hasBeenInteractedWith &&
    !getHasBeenInteractedWithInSession() &&
    !isNativeAppiOS() &&
    !isAndroidTWA &&
    isMobileDevice;

  const prompt: Prompt = timesHasBeenInteractedWith === 0 ? "modal" : "banner";

  return {
    canShow,
    prompt,
    isStudent: isDashboardRoute,
    setHasBeenInteractedWith: () => setHasBeenInteractedWith(true)
  };
}
